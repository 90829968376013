

import React, { PureComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Card, CardGroup, Button, Image, Tab, Nav, Row, Col, Table, Modal, Form, ListGroup, Badge } from 'react-bootstrap'

import app, { fetchOrders } from "../firebase"
import { useAuth } from "../contexts/FirebaseAuth"
import { useFlashUpdate } from "../contexts/FlashContext"
// import firebase from "firebase"
import firebase from 'firebase/app';
import 'firebase/database';
import { Component } from 'react'
import { QrReader } from 'react-qr-reader'
// import moment

function AccountTab(props) {
    const user = props.user

    const flash = useFlashUpdate()
    const history = useHistory()

    async function handleLogout() {
        await app.auth().signOut()
        console.log("LOGOUT SUCCESS")
        // flash({message:"Logout success. Have a nice day!", variant: "success"})
        history.push("/")
    }

    return (
        <>
            {/* <h2>Account Info</h2> */}
            <p className="lead">Iniciaste sesión como...</p>

            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{ maxWidth: "400px" }}>
                    <Card >
                        <Card.Body>
                            <Image roundedCircle
                                src={user.photoURL}
                                alt="user profile"
                                style={{
                                    marginBottom: 15,
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",

                                }}
                            //height="65px"
                            />

                            {/* <div>Nombre: <pre>{user.displayName}</pre></div> */}
                            <div>Email: <pre>{user.email}</pre></div>
                            {/* <div>User Id: <pre>{user.uid}</pre></div> */}
                        </Card.Body>
                    </Card>

                    <Button variant="link" onClick={handleLogout}>
                        Cerrar sesión
                    </Button>
                </div>
            </Container>
        </>
    )
}

class OrdersTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            events: [],
            modalShow: false,
            newEventName: '',
            newEventDate: new Date(),
            modalEditShow: false,
            qrModalShow: false,
            eventKeyToPair: '',
        }
    }

    componentDidMount() {
        firebase.database().ref('sessions').orderByChild('u').equalTo(this.props.user.uid).on('value', (snap) => {
            const s = snap.val()
            // console.log(s)
            const arr = []
            if (s) {
                for (let i in s) {
                    arr.push(s[i])
                }
                this.setState({ events: arr.sort((a, b) => (a.ca < b.ca ? 1 : -1)) })
            } else {
                this.setState({ events: [] })
            }
        })
    }

    parseDate(x) {
        const d = new Date(x)
        const date = d.getUTCFullYear() + '.' + (d.getUTCMonth() + 1) + '.' + d.getUTCDate()
        return (date)
    }

    render() {
        return (
            <div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }} >
                    <Button onClick={() => {
                        this.setState({ modalShow: true })
                    }}>Nuevo evento</Button>
                </div>
                <br></br>
                <p className="lead">{this.state.events.length > 0 ? 'Estos son tus eventos...' : 'Aún no tienes eventos creados...'}</p>
                {
                    this.state.events.map((l, i) => (
                        <div key={i}>
                            <Card onClick={() => {
                                this.setState({ editKey: l.k, modalEditShow: true })
                            }}>
                                <Card.Body>
                                    <Card.Title>{l.n}</Card.Title>
                                    <Card.Text>
                                        Id: {l.k}
                                    </Card.Text>
                                    <Card.Text>
                                        Fecha: {this.parseDate(l.t)}
                                    </Card.Text>
                                    <Card.Text>
                                        Estado: {l.st}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">Administrar evento</small>
                                </Card.Footer>
                            </Card>
                            <br></br>
                        </div>
                    ))
                }
                <Modal show={this.state.modalShow} onHide={() => {
                    this.setState({ modalShow: false })
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nuevo evento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Nombre del evento</Form.Label>
                                <Form.Control type="text" placeholder="Nombre" onChange={(x) => {
                                    this.setState({ newEventName: x.target.value })
                                }} />
                                {/* <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text> */}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Fecha del evento</Form.Label>
                                <Form.Control type="date" placeholder="Fecha" onChange={(x) => {
                                    const t = (new Date(x.target.value)).getTime()
                                    this.setState({ newEventDate: t })
                                    console.log(t)
                                }} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({ modalShow: false, newEventName: '', newEventDate: '' })
                        }}>
                            Cerrar
                        </Button>
                        <Button variant="primary" disabled={!(this.state.newEventName != '' && this.state.newEventDate != '')} onClick={() => {
                            const k = firebase.database().ref().push().key
                            firebase.database().ref('sessions/' + k).set({
                                n: this.state.newEventName,
                                ca: Date.now(),
                                st: 1,
                                u: this.props.user.uid,
                                t: this.state.newEventDate,
                                b: {
                                    aus: true,
                                    as: true,
                                    it: 3
                                },
                                k: k
                            }, () => {
                                this.setState({ modalShow: false, newEventName: '', newEventDate: '' })
                            })

                        }}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                {
                    this.state.modalEditShow
                        ?
                        <ModalEditEvent editKey={this.state.editKey} closeFn={() => { this.setState({ modalEditShow: false }) }}></ModalEditEvent>
                        :
                        <></>
                }
            </div >
        )
    }
}

class ModalEditEvent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            behaviors: {},
            modalEditShow: true,
            qrModalShow: false,
            eventName: '...',
            screensCounter: 0,
            modalAllScreensDeleteShow: false
        }
    }
    componentDidMount() {
        firebase.database().ref('sessions/' + this.props.editKey).on('value', (snap) => {
            const s = snap.val()
            if (s) {
                this.setState({ eventName: s.n, behaviors: s.b || {} }, () => {
                    console.log(this.state.behaviors)
                })
            }
        })
        firebase.database().ref('screens').orderByChild('s').equalTo(this.props.editKey).on('value', (snap) => {
            const s = snap.val()
            if (s) {
                this.setState({ screensCounter: Object.keys(s).length })
            } else {
                this.setState({ screensCounter: 0 })
            }
        })
    }
    componentWillUnmount() {
        firebase.database().ref('screens').orderByChild('s').equalTo(this.props.editKey).off()
        firebase.database().ref('sessions/' + this.props.editKey).off()
    }
    handleClose = () => {
        this.setState({ modalEditShow: false }, () => {
            setTimeout(() => {
                this.props.closeFn()
            }, 250)
        })
    }
    render() {
        return (
            <>
                <Modal show={this.state.modalEditShow} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.eventName}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ListGroup as="ol">
                            <ListGroup.Item
                                onClick={() => {
                                    this.setState({ qrModalShow: true })
                                }}
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="ms-2 me-auto">
                                    Enlazar una nueva pantalla
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item disabled={!(this.state.screensCounter > 0)}
                                onClick={() => {
                                    this.setState({ modalAllScreensDeleteShow: true })
                                }}
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="ms-2 me-auto">
                                    Eliminar todas las pantallas
                                </div>
                                <Badge variant="secondary" pill>
                                    {this.state.screensCounter}
                                </Badge>
                            </ListGroup.Item>
                        </ListGroup>
                        <br></br>
                        <h5>Comportamientos</h5>
                        <ListGroup as="ol">
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="ms-2 me-auto">
                                    Permitir que los usuarios compartan contenido
                                </div>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="share-switch"
                                        checked={this.state.behaviors.aus || false}
                                        onChange={(x) => {
                                            this.state.behaviors.aus = x.target.checked
                                            this.setState({
                                                behaviors: this.state.behaviors
                                            })
                                            firebase.database().ref('sessions/' + this.props.editKey + '/b').child('aus').set(x.target.checked).then(() => {
                                                this.state.behaviors.aus = x.target.checked
                                                this.setState({
                                                    behaviors: this.state.behaviors
                                                })
                                            }).catch(() => {
                                                this.state.behaviors.aus = !x.target.checked
                                                this.setState({
                                                    behaviors: this.state.behaviors
                                                })
                                            })
                                        }}
                                    />
                                </Form>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="ms-2 me-auto">
                                    {/* <div className="fw-bold">Compartir</div> */}
                                    Permitir reproducción en pantallas
                                </div>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="screen-switch"
                                        // checked={true}
                                        checked={this.state.behaviors.as || false}
                                        onChange={(x) => {
                                            this.state.behaviors.as = x.target.checked
                                            this.setState({
                                                behaviors: this.state.behaviors
                                            })
                                            firebase.database().ref('sessions/' + this.props.editKey + '/b').child('as').set(x.target.checked).then(() => {
                                                this.state.behaviors.as = x.target.checked
                                                this.setState({
                                                    behaviors: this.state.behaviors
                                                })
                                            }).catch(() => {
                                                this.state.behaviors.as = !x.target.checked
                                                this.setState({
                                                    behaviors: this.state.behaviors
                                                })
                                            })
                                        }}
                                    />
                                </Form>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="ms-2 me-auto">
                                    Duración de cada foto
                                </div>
                                <Form.Group controlId="exampleForm.ControlSelect1" onChange={(x) => {
                                    this.state.behaviors.it = x.target.value
                                    this.setState({
                                        behaviors: this.state.behaviors
                                    },()=>{
                                        firebase.database().ref('sessions/' + this.props.editKey + '/b').child('it').set(x.target.value).then(() => {
                                            this.state.behaviors.it = x.target.value
                                            this.setState({
                                                behaviors: this.state.behaviors
                                            })
                                        }).catch(() => {
                                            this.state.behaviors.it = 0
                                            this.setState({
                                                behaviors: this.state.behaviors
                                            })
                                        })
                                    })
                                }}>
                                    {/*<Form.Label>Example select</Form.Label>*/}
                                    <Form.Control as="select">
                                        <option selected={this.state.behaviors.it == 1 || false}>1</option>
                                        <option selected={this.state.behaviors.it == 2 || false}>2</option>
                                        <option selected={this.state.behaviors.it ? this.state.behaviors.it == 3 ? true : false : true}>3</option>
                                        <option selected={this.state.behaviors.it == 4 || false}>4</option>
                                        <option selected={this.state.behaviors.it == 5 || false}>5</option>
                                        <option selected={this.state.behaviors.it == 6 || false}>6</option>
                                        <option selected={this.state.behaviors.it == 7 || false}>7</option>
                                        <option selected={this.state.behaviors.it == 8 || false}>8</option>
                                        <option selected={this.state.behaviors.it == 9 || false}>9</option>
                                        <option selected={this.state.behaviors.it == 10 || false}>10</option>
                                    </Form.Control>
                                </Form.Group>
                            </ListGroup.Item>
                        </ListGroup>
                        <br></br>

                        <ListGroup as="ol">
                            <ListGroup.Item>
                                <Button variant='danger' block onClick={() => {
                                    this.setState({ eventKeyToDelete: this.props.editKey, modalDeleteShow: true })
                                }}>
                                    Eliminar evento
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cerrar
                        </Button>
                        {/* <Button variant="primary" onClick={() => { }}>
                        Save Changes
                    </Button> */}
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.qrModalShow} onHide={() => {
                    this.setState({ qrModalShow: false })
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enlazar pantalla</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {
                                this.state.qrModalShow
                                    ?
                                    <QrReader
                                        
                                        onResult={(result, error) => {
                                            
                                            if (!!result) {
                                                const x = result?.text
                                                if (x) {
                                                    firebase.database().ref('screens/' + x).once('value', (snap) => {
                                                        if (snap.val()) {
                                                            firebase.database().ref('screens/' + x).child('s').set(this.props.editKey).then(() => {
                                                                this.setState({ qrModalShow: false })
                                                            })
                                                        }
                                                    })
                                                }
                                            }

                                            if (!!error) {
                                                // this.setState({ qrModalShow: false })
                                            }
                                        }}
                                        
                                        constraints={{facingMode:{ideal:'environment'}}}
                                        ViewFinder={false}
                                        scanDelay={500}
                                        
                                        style={{ width: '100%' }}
                                    />
                                    :
                                    <></>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <p>Para enlazar una pantalla, ingresa en un navegador a <b>screen.muchpic.com</b> y escaneá el código QR</p>
                        <Button variant='secondary' onClick={() => { this.setState({ qrModalShow: false }) }}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.modalDeleteShow} onHide={() => {
                    this.setState({ modalDeleteShow: false })
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar evento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estás por eliminar este evento, se borrará todo el contenido del evento, incluyendo información, fotos, etc. Esta acción no puede deshacerse</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({ modalDeleteShow: false })
                        }}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={() => {
                            //pendiente
                            //borrar pantallas 
                            //borrar fotos del almacenamiento
                            firebase.database().ref('sessions').child(this.state.eventKeyToDelete).set(null).then(() => {
                                this.setState({ modalDeleteShow: false, modalEditShow: false, eventKeyToDelete: '' }, this.handleClose)
                            })
                        }}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.modalAllScreensDeleteShow} onHide={() => {
                    this.setState({ modalAllScreensDeleteShow: false })
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar todas las pantallas</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Estás por eliminar todas las pantallas, esta acción no puede deshacerse. Esto NO modificará el contenido del evento, información, fotos, etc.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({ modalAllScreensDeleteShow: false })
                        }}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={() => {
                            firebase.database().ref('screens').orderByChild('s').equalTo(this.props.editKey).once('value', (snap) => {
                                const s = snap.val()
                                if (s) {
                                    for (let i in s) {
                                        firebase.database().ref('screens').child(i).set(null)
                                    }
                                }
                            }).then(() => {
                                this.setState({ modalAllScreensDeleteShow: false })
                            })
                        }}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default function Profile() {
    const { currentUser } = useAuth()

    //console.log("WINDOW LOCATION", window.location)
    var activeTabKey = "orders"
    if (window.location.hash === "#account") {
        activeTabKey = "account"
    }

    return (
        <Container>
            {/* <h1>Perfil</h1> */}

            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTabKey} transition={false}>
                <Nav variant="tabs" style={{ marginBottom: "1em" }}>
                    <Nav.Item>
                        <Nav.Link eventKey="orders">Eventos</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="account">Perfil</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="account">
                        <AccountTab user={currentUser} />
                    </Tab.Pane>

                    <Tab.Pane eventKey="orders">
                        <OrdersTab user={currentUser} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>
    )
}
